.navigation {
  margin-bottom: 15px;
  background: var(--color-primary);

  & [class*=block-grid-] {
    margin: 0;
  }

  & a {
    font-family: var(--font-sourcesansprosemibolditalic);
    color: white;
    display: block;
    margin: 10px 0;
    font-size: 16px;

    @media (--large-up) {
      text-align: center;
    }

    &:--enter {
      text-decoration: underline;
    }
  }

  & li:--enter, & li.active {
    background: rgba(000,000,000,.2);
  }

  & li {
    border-bottom: 1px solid white;

    &:last-child {
      border: 0;
    }

    @media (--large-up) {
      border: 0;
    }
  }


  & .navigation__menu {
    border: 0;

    @media (--large-up) {
      display: none;
    }

    & ~ li {
      display: none;
      @media (--large-up) {
        display: block;
      }
    }
    & a {
      text-decoration: none !important;
    }
  }
}
