.content {

  & h1 {
    font-family: var(--font-sourcesansprosemibolditalic);
    font-size: 20px;
  }

  & h2 {
      font-family: var(--font-sourcesansprosemibolditalic);
    font-size: 16px;
    font-weight: normal;
    color: var(--color-primary);
  }

  & p {
    color: #666;
    font-size: 13px;
  }

  & .content__teaser {
    margin: 25px 0;
    background: #f8f8f8;


    overflow: hidden;

    & > * {
      float: left;
    }

    & .content__teaser--image {
      width: 30%;
      border-right: 8px solid #bbb;

      @media (--small) {
        width: 100%;
        border: 0;
      }

      @media (--medium) {
        display: none;
      }

      & img {
        display: block;
        border-right: 20px solid white;

        @media (--small) {
          width: 100%;
          border: 0;
        }
      }
    }

    & .content__teaser--text {
      width: 70%;
      font-family: var(--font-sourcesansproitalic);

      @media (--small) {
        width: 100%;
      }
      @media (--medium) {
        width: 100%;
      }
    }

    & blockquote {
      background: #f8f8f8;
      margin: 0;
      padding: 0 20px;

      & p {
        line-height: 1.5;
        color: #333;
        font-size: 300;
      }
    }
  }

  & .content__right {

    & .headline {
      font-family: var(--font-sourcesansprosemibolditalic);
      font-size: 16px;
      margin: 15px  0 0 0;
    }

    & [class*=block-grid-] {
      margin: 0;
    }

    & .team__item {
      border-bottom: 1px solid #ccc;
      font-family: var(--font-sourcesansproitalic);
      overflow: hidden;
      padding: 20px 0;

      @media (--medium) {
        border: 0;
      }

      &:last-child {
        border: 0;
      }

      & img {
        float: left;
        margin-right: 15px;
      }

      & h3 {
        margin: 0;
        color: var(--color-primary);
        font-family: var(--font-sourcesansprosemibolditalic);
      }
      & p {
        margin: 10px 0 0 0;
        color: #333;
        font-size: 300;
      }
    }
  }
}
