/* Wa Global Forms Styles ***************************************** */
/* Bitte nicht mehr als 3 Ebenen verschachteln & ID#s vermeiden */
/* Form Styles Input */
input[type=text],
input[type=email],
input[type=search],
input[type=url],
input[type=tel],
input[type=number],
input[type=password],
textarea,
selectt {
  display: block;
  width: 100%;
  line-height: var(--global-line-height);
  font-family: inherit;
  border: 1px solid var(--input-border-color);
  padding: var(--input-padding);
  background-color: white;
  color: var(--color-dark);
  border-radius: var(--global-border-radius);
  box-shadow: inset 0 1px 2px rgba(10,10,10,0.1);
  appearance:none;
  font-size: 14px;

  @media (--large-up) {
    width: 85%;
  }
}

button {
  border: 0;
}

select {
  background-image: url(var(--img-src)/select-arrow.png);
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
}

[type=submit], .green {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: var(--global-border-radius);
}
