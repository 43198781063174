.leistungen {
  & .leistungen__item {
    margin-bottom: 5px;
    & > a {
      background: var(--color-grey);
      padding: 10px;
      display: block;
      color: var(--color-dark);
      font-weight: 700;
      overflow: hidden;

      & i {
        float: right;
      }

      & + * {
        padding: 10px;
        border: 1px solid var(--color-grey);
      }
    }


  }
}
