/* Grid Sytem */
.grid-row {
  margin: 0 auto;
  max-width: var(--site-max-width);
  width: 100%;
}

.grid-row:before, .grid-row:after {
  content: " ";
  display: table;
}

.grid-row:after {
  clear: both;
}

.grid-row.collapse > .grid-column,
  .grid-row.collapse > .grid-columns {
  padding-left: 0;
  padding-right: 0;
}

.grid-row.collapse .grid-row {
  margin-left: 0;
  margin-right: 0;
}

.grid-row .grid-row {
  margin: 0 calc( var(--grid-space) - ( var(--grid-space) * 2 ) );
  max-width: none;
  width: auto;
}

.grid-row .grid-row:before, .grid-row .grid-row:after {
  content: " ";
  display: table;
}

.grid-row .grid-row:after {
  clear: both;
}

.grid-row .grid-row.collapse {
  margin: 0;
  max-width: none;
  width: auto;
}

.grid-row .grid-row.collapse:before, .grid-row .grid-row.collapse:after {
  content: " ";
  display: table;
}

.grid-row .grid-row.collapse:after {
  clear: both;
}

/* Columns Grid */
.grid-column,
.grid-columns {
  padding-left: var(--grid-space, 15px);
  padding-right: var(--grid-space, 15px);
  width: 100%;
  float: left;
}

.grid-column + .grid-column:last-child,
.grid-columns + .grid-column:last-child, .grid-column +
.grid-columns:last-child,
.grid-columns +
.grid-columns:last-child {
  float: right;
}

.grid-column + .grid-column.end,
.grid-columns + .grid-column.end, .grid-column +
.grid-columns.end,
.grid-columns +
.grid-columns.end {
  float: left;
}

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .small-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .small-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .small-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .small-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .small-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .small-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .small-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .small-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .small-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .small-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .small-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .small-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .small-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .small-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .small-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .small-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .small-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .small-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .small-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .grid-column,
  .grid-columns {
    position: relative;
    padding-left: var(--grid-space, 15px);
    padding-right: var(--grid-space, 15px);
    float: left;
  }

  .small-1 {
    width: 8.33333%;
  }

  .small-2 {
    width: 16.66667%;
  }

  .small-3 {
    width: 25%;
  }

  .small-4 {
    width: 33.33333%;
  }

  .small-5 {
    width: 41.66667%;
  }

  .small-6 {
    width: 50%;
  }

  .small-7 {
    width: 58.33333%;
  }

  .small-8 {
    width: 66.66667%;
  }

  .small-9 {
    width: 75%;
  }

  .small-10 {
    width: 83.33333%;
  }

  .small-11 {
    width: 91.66667%;
  }

  .small-12 {
    width: 100%;
  }

  .small-offset-0 {
    margin-left: 0 !important;
  }

  .small-offset-1 {
    margin-left: 8.33333% !important;
  }

  .small-offset-2 {
    margin-left: 16.66667% !important;
  }

  .small-offset-3 {
    margin-left: 25% !important;
  }

  .small-offset-4 {
    margin-left: 33.33333% !important;
  }

  .small-offset-5 {
    margin-left: 41.66667% !important;
  }

  .small-offset-6 {
    margin-left: 50% !important;
  }

  .small-offset-7 {
    margin-left: 58.33333% !important;
  }

  .small-offset-8 {
    margin-left: 66.66667% !important;
  }

  .small-offset-9 {
    margin-left: 75% !important;
  }

  .small-offset-10 {
    margin-left: 83.33333% !important;
  }

  .small-offset-11 {
    margin-left: 91.66667% !important;
  }

  .small-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .grid-column.small-centered,
  .grid-columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .grid-column.small-uncentered,
  .grid-columns.small-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .grid-column.small-centered:last-child,
  .grid-columns.small-centered:last-child {
    float: none;
  }

  .grid-column.small-uncentered:last-child,
  .grid-columns.small-uncentered:last-child {
    float: left;
  }

  .grid-column.small-uncentered.opposite,
  .grid-columns.small-uncentered.opposite {
    float: right;
  }

  .grid-row.small-collapse > .grid-column,
  .grid-row.small-collapse > .grid-columns {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-row.small-collapse .grid-row {
    margin-left: 0;
    margin-right: 0;
  }

  .grid-row.small-uncollapse > .grid-column,
  .grid-row.small-uncollapse > .grid-columns {
    padding-left: var(--grid-space, 15px);
    padding-right: var(--grid-space, 15px);
    float: left;
  }
}

@media (--medium) {
  .medium-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .medium-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .medium-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .medium-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .medium-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .medium-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .medium-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .medium-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .medium-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .medium-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .medium-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .medium-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .medium-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .medium-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .medium-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .medium-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .medium-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .medium-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .medium-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .medium-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .medium-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .grid-column,
  .grid-columns {
    position: relative;
    padding-left: var(--grid-space, 15px);
    padding-right: var(--grid-space, 15px);
    float: left;
  }

  .medium-1 {
    width: 8.33333%;
  }

  .medium-2 {
    width: 16.66667%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-4 {
    width: 33.33333%;
  }

  .medium-5 {
    width: 41.66667%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-7 {
    width: 58.33333%;
  }

  .medium-8 {
    width: 66.66667%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-10 {
    width: 83.33333%;
  }

  .medium-11 {
    width: 91.66667%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-offset-0 {
    margin-left: 0 !important;
  }

  .medium-offset-1 {
    margin-left: 8.33333% !important;
  }

  .medium-offset-2 {
    margin-left: 16.66667% !important;
  }

  .medium-offset-3 {
    margin-left: 25% !important;
  }

  .medium-offset-4 {
    margin-left: 33.33333% !important;
  }

  .medium-offset-5 {
    margin-left: 41.66667% !important;
  }

  .medium-offset-6 {
    margin-left: 50% !important;
  }

  .medium-offset-7 {
    margin-left: 58.33333% !important;
  }

  .medium-offset-8 {
    margin-left: 66.66667% !important;
  }

  .medium-offset-9 {
    margin-left: 75% !important;
  }

  .medium-offset-10 {
    margin-left: 83.33333% !important;
  }

  .medium-offset-11 {
    margin-left: 91.66667% !important;
  }

  .medium-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .grid-column.medium-centered,
  .grid-columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .grid-column.medium-uncentered,
  .grid-columns.medium-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .grid-column.medium-centered:last-child,
  .grid-columns.medium-centered:last-child {
    float: none;
  }

  .grid-column.medium-uncentered:last-child,
  .grid-columns.medium-uncentered:last-child {
    float: left;
  }

  .grid-column.medium-uncentered.opposite,
  .grid-columns.medium-uncentered.opposite {
    float: right;
  }

  .grid-row.medium-collapse > .grid-column,
  .grid-row.medium-collapse > .grid-columns {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-row.medium-collapse .grid-row {
    margin-left: 0;
    margin-right: 0;
  }

  .grid-row.medium-uncollapse > .grid-column,
  .grid-row.medium-uncollapse > .grid-columns {
    padding-left: var(--grid-space, 15px);
    padding-right: var(--grid-space, 15px);
    float: left;
  }

  .push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
}

@media (--large) {

  .large-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .large-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .large-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .large-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .large-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .large-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .large-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .large-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .large-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .large-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .large-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .large-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .large-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .large-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .large-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .large-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .large-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .large-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .grid-column,
  .grid-columns {
    position: relative;
    padding-left: var(--grid-space, 15px);
    padding-right: var(--grid-space, 15px);
    float: left;
  }

  .large-1 {
    width: 8.33333%;
  }

  .large-2 {
    width: 16.66667%;
  }

  .large-3 {
    width: 25%;
  }

  .large-4 {
    width: 33.33333%;
  }

  .large-5 {
    width: 41.66667%;
  }

  .large-6 {
    width: 50%;
  }

  .large-7 {
    width: 58.33333%;
  }

  .large-8 {
    width: 66.66667%;
  }

  .large-9 {
    width: 75%;
  }

  .large-10 {
    width: 83.33333%;
  }

  .large-11 {
    width: 91.66667%;
  }

  .large-12 {
    width: 100%;
  }

  .large-offset-0 {
    margin-left: 0 !important;
  }

  .large-offset-1 {
    margin-left: 8.33333% !important;
  }

  .large-offset-2 {
    margin-left: 16.66667% !important;
  }

  .large-offset-3 {
    margin-left: 25% !important;
  }

  .large-offset-4 {
    margin-left: 33.33333% !important;
  }

  .large-offset-5 {
    margin-left: 41.66667% !important;
  }

  .large-offset-6 {
    margin-left: 50% !important;
  }

  .large-offset-7 {
    margin-left: 58.33333% !important;
  }

  .large-offset-8 {
    margin-left: 66.66667% !important;
  }

  .large-offset-9 {
    margin-left: 75% !important;
  }

  .large-offset-10 {
    margin-left: 83.33333% !important;
  }

  .large-offset-11 {
    margin-left: 91.66667% !important;
  }

  .large-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .grid-column.large-centered,
  .grid-columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .grid-column.large-uncentered,
  .grid-columns.large-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .grid-column.large-centered:last-child,
  .grid-columns.large-centered:last-child {
    float: none;
  }

  .grid-column.large-uncentered:last-child,
  .grid-columns.large-uncentered:last-child {
    float: left;
  }

  .grid-column.large-uncentered.opposite,
  .grid-columns.large-uncentered.opposite {
    float: right;
  }

  .grid-row.large-collapse > .grid-column,
  .grid-row.large-collapse > .grid-columns {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-row.large-collapse .grid-row {
    margin-left: 0;
    margin-right: 0;
  }

  .grid-row.large-uncollapse > .grid-column,
  .grid-row.large-uncollapse > .grid-columns {
    padding-left: var(--grid-space, 15px);
    padding-right: var(--grid-space, 15px);
    float: left;
  }

  .push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
}

/* Block Grid */

[class*="block-grid-"] {
  display: block;
  padding: 0;
  margin: 0 calc( var(--block-grid-space) - (var(--block-grid-space)*2) );;
}

[class*="block-grid-"]:before, [class*="block-grid-"]:after {
  content: " ";
  display: table;
}

[class*="block-grid-"]:after {
  clear: both;
}

[class*="block-grid-"] > li {
  display: block;
  float: left;
  height: auto;
  padding: 0 var(--block-grid-space);
}

/* Normal Block Grid */
@media only screen {

  .small-block-grid-1 > li {
    list-style: none;
    width: 100%;
  }

  .small-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .small-block-grid-2 > li {
    list-style: none;
    width: 50%;
  }

  .small-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .small-block-grid-3 > li {
    list-style: none;
    width: 33.33333%;
  }

  .small-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .small-block-grid-4 > li {
    list-style: none;
    width: 25%;
  }

  .small-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .small-block-grid-5 > li {
    list-style: none;
    width: 20%;
  }

  .small-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .small-block-grid-6 > li {
    list-style: none;
    width: 16.66667%;
  }

  .small-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  /*.small-block-grid-7 > li {
    list-style: none;
    width: 14.28571%;
  }

  .small-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .small-block-grid-8 > li {
    list-style: none;
    width: 12.5%;
  }

  .small-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .small-block-grid-9 > li {
    list-style: none;
    width: 11.11111%;
  }

  .small-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .small-block-grid-10 > li {
    list-style: none;
    width: 10%;
  }

  .small-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .small-block-grid-11 > li {
    list-style: none;
    width: 9.09091%;
  }

  .small-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .small-block-grid-12 > li {
    list-style: none;
    width: 8.33333%;
  }

  .small-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }*/
}

  /* Medium Block Grid */
@media (--medium) {

  .medium-block-grid-1 > li {
    list-style: none;
    width: 100%;
  }

  .medium-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .medium-block-grid-2 > li {
    list-style: none;
    width: 50%;
  }

  .medium-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .medium-block-grid-3 > li {
    list-style: none;
    width: 33.33333%;
  }

  .medium-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .medium-block-grid-4 > li {
    list-style: none;
    width: 25%;
  }

  .medium-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .medium-block-grid-5 > li {
    list-style: none;
    width: 20%;
  }

  .medium-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .medium-block-grid-6 > li {
    list-style: none;
    width: 16.66667%;
  }

  .medium-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }
/*
  .medium-block-grid-7 > li {
    list-style: none;
    width: 14.28571%;
  }

  .medium-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .medium-block-grid-8 > li {
    list-style: none;
    width: 12.5%;
  }

  .medium-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .medium-block-grid-9 > li {
    list-style: none;
    width: 11.11111%;
  }

  .medium-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .medium-block-grid-10 > li {
    list-style: none;
    width: 10%;
  }

  .medium-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .medium-block-grid-11 > li {
    list-style: none;
    width: 9.09091%;
  }

  .medium-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .medium-block-grid-12 > li {
    list-style: none;
    width: 8.33333%;
  }

  .medium-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }*/
}

  /* Large Block Grid */
@media (--large) {

  .large-block-grid-1 > li {
    list-style: none;
    width: 100%;
  }

  .large-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .large-block-grid-2 > li {
    list-style: none;
    width: 50%;
  }

  .large-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .large-block-grid-3 > li {
    list-style: none;
    width: 33.33333%;
  }

  .large-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .large-block-grid-4 > li {
    list-style: none;
    width: 25%;
  }

  .large-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .large-block-grid-5 > li {
    list-style: none;
    width: 20%;
  }

  .large-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .large-block-grid-6 > li {
    list-style: none;
    width: 16.66667%;
  }

  .large-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  /*.large-block-grid-7 > li {
    list-style: none;
    width: 14.28571%;
  }

  .large-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .large-block-grid-8 > li {
    list-style: none;
    width: 12.5%;
  }

  .large-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .large-block-grid-9 > li {
    list-style: none;
    width: 11.11111%;
  }

  .large-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .large-block-grid-10 > li {
    list-style: none;
    width: 10%;
  }

  .large-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .large-block-grid-11 > li {
    list-style: none;
    width: 9.09091%;
  }

  .large-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .large-block-grid-12 > li {
    list-style: none;
    width: 8.33333%;
  }

  .large-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }*/
}
