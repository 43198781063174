html {
  font-family: var(--font-primary);
  box-sizing: border-box;

  & *, & *:before, & *:after {
    box-sizing: inherit;
  }
}

body {
  min-width: 320px;
}

/* Pseudo Element Breakpoints for JS */
body::before {
  content: "small";
  display: none;

  @media (--medium) {
    content: "medium";
  }

  @media (--large) {
    content: "large";
  }
}

main {
  position: relative;
  z-index: 5;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

iframe {
  width: 100%;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: var(--color-primary);
  & img {
    border: none;
  }
}

p, ul {
  line-height: var(--global-line-height);
  font-size: var(--font-size);
}

ul {
  margin: 0;
  padding: 0;
   & li {
     list-style: none;
   }
}

*:--enter {
  outline: 0;
}

.home__slider > div {
border-bottom: 4px solid var(--color-primary);
}
