.header {
  margin: 14px auto;

  & .header__list {

    @media (--small) {
      margin-top: 6px;
    }

    & * {
      font-size: 15px;
      margin-right: 10px;
      color: #989898;
      line-height: 1.5;
    }

    & a:--enter {
      text-decoration: underline;
    }

    & *:not(i) {
      font-family: var(--font-sourcesansprosemibolditalic);
    }

    & i {
      color: var(--color-primary);
      font-size: 18px;
    }
  }
}
