.footer {
  margin: 20px auto;
  border-top: 10px solid #e6e6e6;

  & a {
    color: #999;
    font-family: var(--font-sourcesansprosemibolditalic);
    padding: 10px 0;
    display: block;
    text-align: center;

    @media (--small) {
      padding: 6px 0;
    }
  }
}
